<template>
  <div class="cra-max-content">
    <div class="shs-header">
      <the-menu v-if="isMenu" />
      <v-app-bar width="100vw" extension-height="70" outlined>
        <v-toolbar-items class="d-flex align-center">
          <router-link to="/accounts"
            ><v-img src="@/assets/sh_logo_rgb.svg" width="125" height="30" class="mr-10"
          /></router-link>
          <div v-if="$vuetify.breakpoint.mdAndUp">
            <span class="app-title-headline text-small">NAM at Risk Accounts App</span>
            <v-chip
              v-if="getLoggedInUser && !!getLoggedInUser.hasAdminRights"
              class="ml-3"
              label
              outlined
              small
              color="#666666"
              >Admin access
            </v-chip>
            <v-chip
              v-if="getLoggedInUser && !!getLoggedInUser.readOnly"
              class="ml-3"
              label
              outlined
              small
              color="#666666"
              >Read only access</v-chip
            >
          </div>
        </v-toolbar-items>
        <v-spacer />
        <search-form
          v-if="$vuetify.breakpoint.mdAndUp && isMenu && isSearchForm"
          class="pr-10 mr-10"
        />
      </v-app-bar>
    </div>
  </div>
</template>
<script>
import SearchForm from '../search/SearchForm';
import TheMenu from './TheMenu';
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeader',
  components: { TheMenu, SearchForm },
  props: {
    isSearchForm: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMenu: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser']),
    ...mapGetters({
      getLoggedInUser: 'users/getLoggedInUser',
    }),
  },
  mounted() {},
};
</script>
