<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    ...mapActions('oidcStore', ['oidcSignInCallback']),
  },
};
</script>
