import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import AccountDetail from '../views/accounts/AccountDetail';
import UserDetail from '../views/users/UserDetail';
import store from '../store';
import AccountOverview from '../views/accounts/AccountOverview';
import Insights from '../views/Insights';
import AccountWatchlist from '../views/accounts/AccountWatchlist';
import UserOverview from '../views/users/UserOverview';
import NotFound from '../views/NotFound';
import AuthLoading from '../components/notifications/AuthLoading.vue';
import AccountOTR from '../views/accounts/AccountOTR';
import AccountArchive from '../views/accounts/AccountArchive';
import AccountDeleted from '../views/accounts/AccountDeleted';
import OidcCallback from '../components/oidcCallback/OidcCallback.vue';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import UserUnauthorized from '../views/UserUnauthorized.vue';

Vue.use(VueRouter);

async function loadUsers() {
  try {
    store.commit('notifications/setBusy', true);

    await store.dispatch('users/loadUsers');

    store.commit('notifications/setBusy', false);
  } catch (e) {
    store.commit('notifications/setBusy', false);
    store.commit('notifications/setSnackbar', {
      type: 'error',
      message: e,
      timeout: 6000,
      visible: true,
    });
  }
}

async function loadAccounts(page, options) {
  try {
    store.commit('notifications/setBusy', true);

    await store.dispatch('accounts/loadAccounts', { page: page, type: options.type });

    store.commit('notifications/setBusy', false);
  } catch (e) {
    store.commit('notifications/setBusy', false);
    store.commit('notifications/setSnackbar', {
      type: 'error',
      message: e,
      timeout: 6000,
      visible: true,
    });
  }
}

const routes = [
  {
    path: '/callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true,
      isOidcCallback: true,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: LoginPage,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: AccountOverview,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Active', 'Submitted'],
      });
      store.commit('filters/setFilter', { fieldName: 'search', value: '' });

      const page = to.query.page || 1;
      await loadAccounts(page, { type: ['Active', 'Submitted'] });

      store.dispatch('users/loadLoggedInUser');
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: 'create',
        name: 'accountcreate',
        component: AccountDetail,
        beforeEnter: (to, from, next) => {
          store.commit('accounts/addAccountInCreation');
          next();
        },
      },
      {
        path: ':id',
        name: 'accountdetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
            store.commit('actionItems/addActionItemInCreation');
            store.commit('accountComments/addCommentInCreation');
            next();
          });
        },
      },
    ],
  },
  {
    path: '/watchlist',
    name: 'watchlist',
    component: AccountWatchlist,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Watchlist'],
      });
      store.dispatch('users/loadLoggedInUser');

      const page = to.query.page || 1;
      await loadAccounts(page, { type: 'Watchlist' });

      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: ':id',
        name: 'watchlistdetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/otr',
    name: 'otr',
    component: AccountOTR,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['OTR'],
      });
      store.dispatch('users/loadLoggedInUser');

      const page = to.query.page || 1;
      await loadAccounts(page, { type: 'OTR' });

      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: ':id',
        name: 'otrdetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/archive',
    name: 'archive',
    component: AccountArchive,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Archived'],
      });
      store.dispatch('users/loadLoggedInUser');

      const page = to.query.page || 1;
      await loadAccounts(page, { type: 'Archived' });

      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: ':id',
        name: 'archivedetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/deleted',
    name: 'deleted',
    component: AccountDeleted,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'accountTypes',
        value: ['Deleted'],
      });
      store.dispatch('users/loadLoggedInUser');

      const page = to.query.page || 1;
      await loadAccounts(page, { type: 'Deleted' });

      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: ':id',
        name: 'deletedetails',
        component: AccountDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('accounts/loadAccountDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/user-management',
    name: 'users',
    component: UserOverview,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.commit('filters/setFilter', {
        fieldName: 'activeUser',
        value: true,
      });
      store.commit('filters/setFilter', { fieldName: 'search', value: '' });
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      next();
    },
    children: [
      {
        path: 'create',
        name: 'usercreate',
        component: UserDetail,
        beforeEnter: (to, from, next) => {
          store.commit('users/addUserInCreation');
          next();
        },
      },
      {
        path: ':id',
        name: 'userdetails',
        component: UserDetail,
        props: true,
        beforeEnter: (to, from, next) => {
          store.dispatch('users/loadUserDetails', to.params.id).then(() => {
            next();
          });
        },
      },
    ],
  },
  {
    path: '/insights',
    name: 'insights',
    component: Insights,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      store.dispatch('users/loadLoggedInUser');
      if (store.getters['users/users'].length === 0) {
        await loadUsers();
      }
      try {
        store.commit('notifications/setBusy', true);
        await store.dispatch('zones/loadZones');
        store.commit('notifications/setBusy', false);
      } catch (e) {
        store.commit('notifications/setBusy', false);
        store.commit('notifications/setSnackbar', {
          type: 'error',
          message: e,
          timeout: 6000,
          visible: true,
        });
      }
      try {
        store.commit('notifications/setBusy', true);
        await store.dispatch('insights/loadInsights');
        store.commit('notifications/setBusy', false);
      } catch (e) {
        store.commit('notifications/setBusy', false);
        store.commit('notifications/setSnackbar', {
          type: 'error',
          message: e,
          timeout: 6000,
          visible: true,
        });
      }
      next();
    },
  },
  {
    path: '/auth-loading',
    name: 'AuthLoading',
    component: AuthLoading,
    meta: { requiresAuth: false },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: UserUnauthorized,
    meta: { requiresAuth: false },
  },
  {
    path: '/:notFound(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
