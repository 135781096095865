import ApiService from '../../../services/ApiService';

export default {
  async loadUsers(context) {
    const response = await ApiService.fetchAllUsers();
    if (!response.data.content) {
      return [];
    }
    const users = [];
    const userResponseList = response.data.content;

    for (const key of userResponseList) {
      const user = {
        firstName: key.firstName,
        lastName: key.lastName,
        id: key.id,
        hasAdminRights: key.hasAdminRights,
        readOnly: key.readOnly,
        orgCode: key.orgCode,
        hasReportAccess: key.hasReportAccess,
        roles: key.roles,
        zones: key.zones,
        isProxy: key.isProxy,
        hasProxy: key.hasProxy,
        active: key.active,
        email: key.email,
      };
      users.push(user);
    }
    context.commit('setUsers', users);
    context.commit('setFetchTimestamp');
  },
  async loadLoggedInUser(context) {
    const response = await ApiService.fetchLoggedInUser();

    context.commit('setLoggedInUser', response.data);
    context.commit('filters/setInitialFilterForLoggedInUser', response.data.filter, { root: true });
  },
  async createUser(context) {
    const userToSave = { ...context.state.userInCreation };

    delete userToSave.savingState;

    context.commit('setUserInCreationSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });

    try {
      await ApiService.createUser(userToSave);
      context.commit('setUserInCreationSavingState', {
        isSaving: false,
        type: 'success',
        message: 'The user submit was successful.',
      });
    } catch (e) {
      let errorMessage = '';
      Object.keys(e).forEach((key) => {
        errorMessage += e[key] + '. ';
      });
      context.commit('setUserInCreationSavingState', {
        isSaving: false,
        type: 'error',
        message: errorMessage,
      });
    }
  },
  async loadUserDetails(context, payload) {
    const response = await ApiService.fetchUserDetails(payload);
    context.commit('setUserDetails', response.data);
  },
  setUserField(context, payload) {
    const { fieldName, id, validators, value } = payload;
    context.commit('patchUserToEdit', { fieldName, value });

    const isValid = validators.every((validator) => {
      return validator(context.state.userToEdit[fieldName]);
    });

    if (!isValid) {
      let oldValue;
      context.state.users.find((user) => {
        if (user.id === id) {
          Object.keys(user).find((key) => {
            if (key === fieldName) {
              oldValue = user[key];
            }
          });
        }
      });
      context.commit('patchUserToEdit', { fieldName, value: oldValue });
    } else {
      /*context.commit('patchUserDetail', payload);
      context.dispatch('saveUser', payload).then((r) => {
        console.log('res then', r);
      });*/
    }
  },
  async saveUser(context) {
    const userToSave = { ...context.state.userToEdit };

    delete userToSave.savingState;
    delete userToSave.loggedInUser;
    delete userToSave.proxySettingsToSave;
    delete userToSave._links;

    context.commit('setUserToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });

    try {
      await ApiService.saveUser(userToSave.id, userToSave);
      const stateUserToEdit = context.state.userToEdit;
      if (
        stateUserToEdit.hasProxy &&
        stateUserToEdit.hasProxy.length &&
        !stateUserToEdit.proxySettingsToSave.hasProxy
      ) {
        await ApiService.deleteProxy(userToSave.id, stateUserToEdit.hasProxy[0].id);
      }
      if (stateUserToEdit.proxySettingsToSave.hasProxy) {
        const periodSort = stateUserToEdit.proxySettingsToSave.periodOfTime.sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
        const proxyToSave = {
          fromDate: periodSort[0],
          proxyUserId: stateUserToEdit.proxySettingsToSave.proxyUser.id,
          toDate: periodSort[1],
        };
        await ApiService.saveProxy(userToSave.id, proxyToSave);
      }
      context.commit('setUserToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Saving changes was successful',
      });
      context.commit('patchUserDetail', userToSave);
      await context.dispatch('loadUsers');
      await context.dispatch('loadLoggedInUser');
    } catch (e) {
      context.commit('setUserToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'An error occurred while saving user changes. Please try again.',
      });
      console.log('error user changes saved', e);
    }
  },
  async archiveUser(context, payload) {
    try {
      await ApiService.archiveUser(payload.id);
      context.commit('patchUserToEdit', { fieldName: 'active', value: false });
      context.commit('patchUserActiveState', { id: payload.id, value: false });
      context.commit(
        'filters/setFilter',
        {
          fieldName: 'activeUser',
          value: false,
        },
        { root: true }
      );
    } catch (e) {
      console.log('error user changes saved', e);
    }
  },
  async activateUser(context, payload) {
    try {
      await ApiService.activateUser(payload.id);
      context.commit('patchUserToEdit', { fieldName: 'active', value: true });
      context.commit('patchUserActiveState', { id: payload.id, value: true });
      context.commit(
        'filters/setFilter',
        {
          fieldName: 'activeUser',
          value: true,
        },
        { root: true }
      );
    } catch (e) {
      console.log('error user changes saved', e);
    }
  },
  async saveLoggedInUserFilter(context, payload) {
    const { filterName, value } = payload;
    const filter = {
      [filterName]: value,
    };
    try {
      await ApiService.setLoggedUserFilters({ filter });
    } catch (e) {
      console.log(e);
    }
  },
};
