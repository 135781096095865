<template>
  <v-row align="center" justify="center">
    <account-edit v-if="accountId" :id="accountId" @close="closeModal" />
    <account-add v-else @save-success="createdAndClose" @close="closeModal" />
  </v-row>
</template>
<script>
import AccountEdit from '../../components/accounts/accountDetail/AccountEdit';
import AccountAdd from '../../components/accounts/AccountAdd';

export default {
  name: 'AccountDetail',
  components: { AccountEdit, AccountAdd },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCreatedAndClose: false,
      leaveCounter: 0,
    };
  },
  computed: {
    accountId() {
      return this.id ? this.id : null;
    },
  },
  mounted() {
    this.modal = true;
  },
  beforeRouteLeave(to, from, next) {
    const page = from.query.account || 1;
    if (this.isCreatedAndClose === true) {
      this.$store.dispatch('accounts/loadAccounts', page);
    }
    this.leaveCounter++;
    next();
  },
  methods: {
    closeModal() {
      if (this.leaveCounter === 0) {
        const path = this.$route.path.substr(0, this.$route.path.lastIndexOf('/'));
        this.$router.replace({ path });
      }
    },
    createdAndClose() {
      this.isCreatedAndClose = true;
      this.closeModal();
    },
  },
};
</script>
