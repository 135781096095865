<template>
  <v-container fluid class="pl-6 pr-6">
    <div class="cra-expand-panel-title" @click="expand = !expand">
      <h4 class="cra-expand-panel-headline">Core team</h4>
      <v-icon v-if="expand" x-large>mdi-chevron-up</v-icon>
      <v-icon v-else x-large>mdi-chevron-down</v-icon>
    </div>
    <v-expand-transition>
      <div v-show="expand">
        <p v-if="!isZoneSelected" class="p-tiny pb-2">
          <strong
            >Please select the zone first in order to be able to select the core team for this
            account.</strong
          >
        </p>
        <v-row>
          <v-col
            v-for="role in getCoreTeamWithAdditionalRoles"
            :key="role"
            cols="12"
            md="3"
            class="mb-6"
          >
            <user-select
              :is-editable="isEditable"
              :items="getSelectUserList(role)"
              :role-name="role"
              :preselected="getCoreMember(role)"
              @change-user="setTeamMember"
            />
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import getInitials from '../../../helpers/getInitials';
import removeSpaces from '../../../helpers/removeSpaces';
import UserSelect from '../../ui/UserSelect';

export default {
  name: 'CoreTeam',
  components: { UserSelect },
  props: {
    zone: {
      type: String,
      required: false,
      default: '',
    },
    definedMembers: {
      type: Object,
      required: false,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['select-team-member'],
  data() {
    return {
      isZoneSelected: Boolean,
      userList: [],
      expand: true,
      fieldsToValidate: {},
    };
  },
  computed: {
    ...mapGetters({
      getUsersByZone: 'users/usersByZone',
      getCoreTeamRoles: 'userRoles/coreTeamRoles',
      getUserById: 'users/userById',
    }),
    getCoreTeamWithAdditionalRoles() {
      return this.getCoreTeamRoles.concat([
        'Additional 1',
        'Additional 2',
        'Additional 3',
        'Additional 4',
      ]);
    },
  },
  watch: {
    zone() {
      this.updateUserList();
    },
  },
  mounted() {
    this.updateUserList();
  },
  methods: {
    updateUserList() {
      this.isZoneSelected = this.zone.length > 0;

      this.userList = this.getUsersByZone(this.zone);
    },
    getSelectUserList(role) {
      const userList = [];
      this.userList.forEach((user) => {
        if (user.roles.includes(role) || role.startsWith('Additional')) {
          const newUser = {
            name: user.firstName + ' ' + user.lastName,
            id: user.id,
            initials: getInitials(user.firstName, user.lastName),
          };
          userList.push(newUser);
        }
      });
      return userList;
    },
    getCoreMember: function (role) {
      if (this.definedMembers === null || this.definedMembers === undefined) {
        return null;
      }
      const selectedMember = Object.keys(this.definedMembers).find(
        (acc) => removeSpaces(role) === acc
      );
      if (selectedMember === undefined) {
        return null;
      }
      return {
        name:
          this.definedMembers[selectedMember].firstName +
          ' ' +
          this.definedMembers[selectedMember].lastName,
        id: this.definedMembers[selectedMember].id,
        initials: getInitials(
          this.definedMembers[selectedMember].firstName,
          this.definedMembers[selectedMember].lastName
        ),
      };
    },
    setTeamMember(data) {
      const user = this.getUserById(data.id);
      const { id, firstName, lastName } = user;
      const userData = {
        role: data.role,
        user: { id, firstName, lastName },
      };
      this.$emit('select-team-member', userData);
    },
  },
};
</script>
