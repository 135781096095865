<template>
  <base-modal @close="close">
    <template v-slot:header>
      <v-row>
        <v-col cols="12" class="pb-0">
          <h3>Add account</h3>
        </v-col>
      </v-row>
    </template>
    <v-form>
      <v-container fluid class="px-6">
        <v-row class="pb-3">
          <v-col cols="12" md="8">
            <v-text-field
              filled
              label="Account name"
              :value="getAccountInCreation && getAccountInCreation.name"
              hide-details
              dense
              @change="(value) => updateAccount({ fieldName: 'name', value })"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              filled
              label="City and State"
              :value="getAccountInCreation && getAccountInCreation.city"
              hide-details
              dense
              @change="(value) => updateAccount({ fieldName: 'city', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pb-6">
          <v-col cols="12" md="4">
            <styled-select
              :label="'Risk level'"
              :items="getRiskLevels"
              :selected="getAccountInCreation && getAccountInCreation.riskLevel"
              :item-class="'cra-select-item-risk-level'"
              :select-class="'cra-select-risk-level'"
              @change-selected="(value) => updateAccount({ fieldName: 'riskLevel', value })"
            >
            </styled-select>
          </v-col>
          <v-col cols="12" md="4">
            <styled-select
              :label="'Customer temperature'"
              :items="getCustomerTemperatures"
              :selected="getAccountInCreation && getAccountInCreation.customerTemperature"
              :item-class="'cra-select-item-customer-temperature'"
              :select-class="'cra-select-customer-temperature'"
              @change-selected="
                (value) => updateAccount({ fieldName: 'customerTemperature', value })
              "
            >
            </styled-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :value="getAccountInCreation && getAccountInCreation.zone"
              :items="getZones"
              label="Zone"
              filled
              dense
              hide-details
              class="cra-select"
              @change="(value) => updateAccount({ fieldName: 'zone', value })"
            />
          </v-col>
        </v-row>
        <v-row class="pb-6">
          <v-col cols="12" class="pb-0">
            <p class="p-tiny mb-0"><strong>Risk level definition</strong></p>
          </v-col>
          <v-col cols="12" md="4">
            <p class="p-tiny cra-headline-riskLevel high mb-3"><strong>High</strong></p>
            <p class="p-tiny">
              Already escalated, written or verbal feedback from the customer stating
              dissatisfaction; if we do not take corrective action then the possibility of
              termination/calling breach within the next 1 - 2 months is highly likely. Therefore
              "make right actions" may need to be taken immediately.
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <p class="p-tiny cra-headline-riskLevel medium mb-3"><strong>Medium</strong></p>
            <p class="p-tiny">
              Already escalated, written or verbal feedback from the customer stating
              dissatisfaction; if we do not take corrective action then the possibility of a
              termination/calling breach within the next 2 -4 months is foreseen. Therefore a plan
              needs to be developed and action need to be taken asap to prevent account going into
              High risk category.
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <p class="p-tiny cra-headline-riskLevel low mb-3"><strong>Low</strong></p>
            <p class="p-tiny">
              Written or verbal feedback from the customer stating dissatisfaction; Corrective/Agile
              action plan TBD based on evaluation on issues which customer is facing. If we do not
              take action, customer temperature can rise and account can turn into Med or High risk
              in near future.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="pb-8" />
      <v-container fluid class="px-6">
        <v-row>
          <v-col cols="12" md="8"><h4>Account description</h4></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-textarea
              :value="getAccountInCreation && getAccountInCreation.description"
              filled
              hide-details
              placeholder="Please add a specific description about the case. Why is it on risk?"
              @change="(value) => updateAccount({ fieldName: 'description', value })"
            />
          </v-col>
          <v-col cols="12" md="4">
            <base-file-input
              :label="'Document upload'"
              :files="getAccountInCreation && getAccountInCreation.files"
              @add-files="(value) => updateAccount({ fieldName: 'files', value })"
              @remove-files="(value) => removeFiles({ fieldName: 'files', value })"
            ></base-file-input>
            <v-alert dense outlined type="warning" icon="mdi-information-outline" class="cra-alert">
              You have to select at least one document. Multiple documents can be uploaded by
              holding down the CTRL-Button
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <chips-select
                  :title="'Account issues'"
                  :selected="getAccountInCreation && getAccountInCreation.accountIssues"
                  :items="getIssues"
                  @change-selected="(value) => updateAccount({ fieldName: 'accountIssues', value })"
                >
                </chips-select>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <chips-select
                  :title="'Lab products'"
                  :selected="getAccountInCreation && getAccountInCreation.labProducts"
                  :items="getLabProducts"
                  @change-selected="(value) => updateAccount({ fieldName: 'labProducts', value })"
                >
                </chips-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="warning"
                  icon="mdi-information-outline"
                  class="cra-alert"
                >
                  Select all issues and products that apply to the account
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  label="SAP contract number"
                  :value="getContractInCreation && getContractInCreation.sapNumber"
                  hide-details
                  @change="(value) => updateContract({ fieldName: 'sapNumber', value })"
                />
              </v-col>
              <v-col cols="12" md="6">
                <base-date-picker
                  :date="getContractInCreation && getContractInCreation.endDate"
                  label="End date"
                  @change-field="(date) => updateContract({ fieldName: 'endDate', value: date })"
                />
              </v-col>
            </v-row>
            <v-row class="pb-0">
              <v-col cols="12" class="pb-0">
                <v-sheet>
                  <base-switch
                    :is-active="getContractInCreation && getContractInCreation.active"
                    :label="'Active contract'"
                    @change-field="
                      (value) => setContractActiveState({ fieldName: 'active', value })
                    "
                  />
                </v-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  filled
                  label="Sold to"
                  :value="getContractInCreation && getContractInCreation.soldTo"
                  hide-details
                  :disabled="!getContractInCreation.active"
                  @change="(value) => updateContract({ fieldName: 'soldTo', value })"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  filled
                  label="Ship To"
                  :value="getContractInCreation && getContractInCreation.shipTo"
                  hide-details
                  :disabled="!getContractInCreation.active"
                  @change="(value) => updateContract({ fieldName: 'shipTo', value })"
                />
              </v-col>
              <v-col cols="12" md="4">
                <base-date-picker
                  :date="getContractInCreation && getContractInCreation.goLive"
                  label="Go Live"
                  :disabled="!getContractInCreation.active"
                  @change-field="(date) => updateContract({ fieldName: 'goLive', value: date })"
                />
              </v-col>
            </v-row>
            <v-row class="pb-6">
              <v-col cols="12" md="8">
                <v-alert
                  dense
                  outlined
                  type="warning"
                  icon="mdi-information-outline"
                  class="cra-alert"
                >
                  You may seperate multiple entries in the sold to / ship to fields by comma
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="pb-3" />
      <core-team
        :zone="getAccountInCreation && getAccountInCreation.zone"
        @select-team-member="updateCoreTeam"
      />
      <v-divider class="pb-8 mt-8" />
      <v-container fluid class="pb-8 px-6">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="6">
            <p class="p-tiny">
              By clicking on the 'Submit account' button, you will submit this account for the
              approval workflow. Customer Success Lead (CSL) and Customer Success Director (CSD)
              have to approve the account before any actions can be started.
            </p>
          </v-col>
          <v-col cols="12" md="3" style="text-align: right">
            <base-confirm-button
              mode="primary"
              icon="mdi-chevron-right"
              :icon-size="'22px'"
              :button-default-text="'Submit account'"
              :button-confirm-text="'Confirm submit'"
              :disabled="!isValid"
              @confirm="submitForm"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-modal>
</template>
<script>
import ValidationService from '../../services/ValidationService';
import { mapGetters } from 'vuex';
import BaseDatePicker from '../ui/BaseDatePicker';
import CoreTeam from './accountDetail/CoreTeam';
import BaseSwitch from '../ui/BaseSwitch';
import BaseFileInput from '../ui/BaseFileInput';
import removeSpacesLowerCase from '../../helpers/removeSpacesLowerCase';
import StyledSelect from '../ui/StyledSelect';
import ChipsSelect from '../ui/ChipsSelect';
import removeSpaces from '../../helpers/removeSpaces';
import BaseConfirmButton from '../ui/BaseConfirmButton';
import BaseModal from '../ui/BaseModal';

export default {
  name: 'AccountAdd',
  components: {
    ChipsSelect,
    StyledSelect,
    BaseFileInput,
    BaseSwitch,
    CoreTeam,
    BaseDatePicker,
    BaseConfirmButton,
    BaseModal,
  },
  emits: ['save-success', 'show-snackbar', 'close'],
  data() {
    return {
      isFormValid: false,
      fieldsToValidate: {
        files: {
          validators: [ValidationService.hasContent],
        },
        name: { validators: [ValidationService.hasContent] },
        city: {
          validators: [ValidationService.hasContent],
        },
        riskLevel: {
          validators: [ValidationService.hasContent],
        },
        customerTemperature: {
          validators: [ValidationService.hasContent],
        },
        zone: {
          validators: [ValidationService.hasContent],
        },
        accountIssues: { validators: [ValidationService.hasContent] },
        labProducts: { validators: [ValidationService.hasContent] },
        description: { validators: [ValidationService.hasContent] },
      },
      contractFieldsToValidate: {
        sapNumber: {
          validators: [ValidationService.hasContent, ValidationService.isSapNumber],
        },
        endDate: { validators: [ValidationService.hasContent] },
        soldTo: {
          conditionalFieldName: 'active',
          conditionalValidators: [ValidationService.isTrue],
          validators: [ValidationService.hasContent],
        },
        shipTo: {
          conditionalFieldName: 'active',
          conditionalValidators: [ValidationService.isTrue],
          validators: [ValidationService.hasContent],
        },
        goLive: {
          conditionalFieldName: 'active',
          conditionalValidators: [ValidationService.isTrue],
          validators: [ValidationService.hasContent],
        },
      },
      snackbar: {
        isOn: false,
        message: '',
        type: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getZones: 'zones/zones',
      getRiskLevels: 'riskLevels/riskLevels',
      getCustomerTemperatures: 'customerTemperatures/customerTemperatures',
      getIssues: 'issues/issues',
      getLabProducts: 'labProducts/labProducts',
      getAccountInCreation: 'accounts/getAccountInCreation',
      getContractInCreation: 'accounts/getContractInCreation',
      getCoreTeamInCreation: 'accounts/getCoreTeamInCreation',
      getAccountInCreationSavingState: 'accounts/getAccountInCreationSavingState',
      getCoreTeamRoles: 'userRoles/coreTeamRoles',
    }),
    isValid() {
      const accountValid = ValidationService.checkFormValidity(
        this.fieldsToValidate,
        this.getAccountInCreation
      );
      const contractValid = ValidationService.checkFormValidity(
        this.contractFieldsToValidate,
        this.getContractInCreation
      );

      const coreTeamValid = this.checkCoreTeamValidity();
      return coreTeamValid && accountValid && contractValid;
    },
    customerTemperatureClass() {
      return removeSpacesLowerCase(this.getAccountInCreation.customerTemperature);
    },
  },
  watch: {
    getAccountInCreationSavingState() {
      if (this.getAccountInCreationSavingState.message.length > 0) {
        this.$store.commit('notifications/setSnackbar', {
          type: this.getAccountInCreationSavingState.type,
          message: this.getAccountInCreationSavingState.message,
        });
      }
    },
  },
  methods: {
    checkCoreTeamValidity() {
      return this.getCoreTeamRoles.every((role) => this.getCoreTeamInCreation[removeSpaces(role)]);
    },
    getSelectFieldClass(val) {
      return removeSpacesLowerCase(val);
    },
    async submitForm() {
      this.$store.commit('notifications/setBusy', true);
      await this.$store.dispatch('accounts/createAccount');
      await this.$nextTick();
      if (
        !this.getAccountInCreationSavingState.isSaving &&
        this.getAccountInCreationSavingState.type === 'success'
      ) {
        this.$emit('save-success');
      }
    },
    updateAccount(data) {
      this.$store.commit('accounts/patchAccountInCreation', { [data.fieldName]: data.value });
      if (data.fieldName === 'zone') {
        this.$store.commit('accounts/resetCoreTeamInCreation');
      }
    },
    updateContract(data) {
      this.$store.commit('accounts/patchContractInCreation', { [data.fieldName]: data.value });
    },
    updateCoreTeam(data) {
      const roleKey = data.role.replace(/ /g, '');

      this.$store.commit('accounts/patchCoreTeamInCreation', { [roleKey]: data.user });
    },
    setContractActiveState(data) {
      this.updateContract(data);
      if (!data.value) {
        this.updateContract({ fieldName: 'soldTo', value: '' });
        this.updateContract({ fieldName: 'shipTo', value: '' });
        this.updateContract({ fieldName: 'goLive', value: null });
      }
    },
    removeFiles(data) {
      this.$store.commit('accounts/removeFileAccountInCreation', { [data.fieldName]: data.value });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
.cra-column-text {
  column-width: 30em;
  column-gap: 2em;
}
</style>
