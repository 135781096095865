<template>
  <div>
    <v-menu v-if="$vuetify.breakpoint.mdAndUp" offset-y max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" class="cra-menu-btn my-4 mr-3" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list min-width="300">
        <base-user-output
          :last-name="user.lastName"
          :first-name="user.firstName"
          :role-name="user.roles"
          class="py-4 px-3 link"
          @click="editUserProfile"
        ></base-user-output>
        <v-list-item
          v-for="(item, index) in activeMenuItems"
          :key="index"
          class="link"
          @click="doLinkAction(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else>
      <v-app-bar-nav-icon class="cra-menu-btn my-3 mx-3" @click.stop="drawer = !drawer" />
      <v-navigation-drawer v-model="drawer" floating absolute app right width="100vw" temporary>
        <div class="d-flex justify-space-between">
          <div class="py-3 pl-3">
            <v-img src="@/assets/sh_logo_rgb.svg" width="125" height="30" class="mr-10" />
          </div>
          <div class="py-3 pr-3">
            <v-btn icon @click="closeMenu">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <v-list dense rounded>
          <base-user-output
            :last-name="user && user.lastName"
            :first-name="user && user.firstName"
            :role-name="user && user.roles"
            class="py-4 px-3"
            @click="editUserProfile"
          ></base-user-output>
          <v-list-item
            v-for="(item, index) in activeMenuItems"
            :key="index"
            @click="doLinkAction(item)"
          >
            {{ item.title }}
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>
<script>
import BaseUserOutput from '../ui/BaseUserOutput';
import { mapGetters } from 'vuex';
import ApiService from '../../services/ApiService';
export default {
  name: 'TheMenu',
  components: { BaseUserOutput },
  data() {
    return {
      drawer: false,
      menuItems: [
        { title: 'Insights dashboard', path: '/insights' },
        { title: 'Active accounts', path: '/accounts' },
        { title: 'Watchlist', path: '/watchlist' },
        { title: 'On the radar', path: '/otr' },
        { title: 'Archive', path: '/archive' },
        { title: 'Recently deleted', path: '/deleted' },
        { title: 'Reporting', action: 'report', permission: 'hasReportAccess' },
        { title: 'Excel export', action: 'export', permission: 'hasReportAccess' },
        { title: 'User management', path: '/user-management' },
        { title: 'Log out', action: 'logout' },
      ],
      activeMenuItems: [],
      user: {
        firstName: '',
        lastName: '',
        id: '',
        roles: '',
        hasReportAccess: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getLoggedInUser: 'users/getLoggedInUser',
    }),
  },
  mounted() {
    Object.keys(this.user).find((key) => {
      if (key !== 'roles') {
        this.user[key] = this.getLoggedInUser[key];
      } else {
        this.user[key] = this.getLoggedInUser[key].join(', ');
      }
    });
    this.setMenuItems();
  },
  methods: {
    setMenuItems() {
      this.menuItems.forEach((item) => {
        if (!item.permission) {
          this.activeMenuItems.push(item);
        } else if (this.user[item.permission]) {
          this.activeMenuItems.push(item);
        }
      });
    },
    closeMenu() {
      this.drawer = false;
    },
    editUserProfile() {
      this.$router.push({ path: '/user-management/' + this.user.id });
    },
    doLinkAction(item) {
      if (item.path && item.path !== this.$route.path) {
        this.$router.push({ path: item.path });
      }
      if (item.action && item.action === 'export') {
        this.downloadReport();
      }
      if (item.action && item.action === 'report') {
        window.open('https://pulse.siemens.cloud/project/riskaccounts/dash/5562', '_blank');
      }
      if (item.action && item.action === 'logout') {
        this.logout();
      }
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ path: '/' });
    },
    async downloadReport() {
      this.$store.commit('notifications/setBusy', true);
      try {
        await ApiService.downloadReport();
        this.$store.commit('notifications/setSnackbar', {
          type: 'success',
          message: 'Download report',
        });
      } catch (e) {
        this.$store.commit('notifications/setSnackbar', {
          type: 'error',
          message: 'Report download failed! ' + e.toString(),
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
.cra-menu-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: +1;
}
.link {
  cursor: pointer;

  &.v-list-item {
    &:hover {
      background: $gray-tint-5;
    }
  }
}
</style>
