import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getInitialActionItemInCreationState = () => ({
  description: '',
  dueDate: null,
  issues: [],
  labProducts: [],
  responsibleUserId: '',
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
  isEditing: false,
  files: [],
  category: [],
});

export const getInitialActionItemToEditState = () => ({
  description: '',
  issues: [],
  labProducts: [],
  savingState: {
    isSaving: false,
    type: '',
    message: '',
  },
  isEditing: false,
  files: [],
});

export const getInitialActionItemsEditsState = () => ({
  newEditId: '',
  oldEditId: '',
});

export const getInitialActionItemState = () => ({
  lastFetch: null,
  actionItems: [],
  actionItemInCreation: getInitialActionItemInCreationState(),
  actionItemEdits: getInitialActionItemsEditsState(),
  actionItemToEdit: null,
  activeFilters: {
    issues: [],
    categories: [],
  },
  allFilters: {
    categories: [
      {
        slug: 'RootCauseAnalysis',
        title: 'Root cause analysis',
      },
      {
        slug: 'SystemStability',
        title: 'System stability',
      },
      {
        slug: 'PartsAvailability',
        title: 'Parts availability',
      },
      {
        slug: 'ReagentsConsumablesBackorder',
        title: 'Reagents/consumables backorder',
      },
      {
        slug: 'RestoreCustomerConfidence',
        title: 'Restore customer confidence',
      },
      {
        slug: 'Communication',
        title: 'Communication',
      },
      {
        slug: 'Training',
        title: 'Training',
      },
      {
        slug: 'Reimbursements',
        title: 'Reimbursements',
      },
      {
        slug: 'TechnicalSupport',
        title: 'Technical support',
      },
      {
        slug: 'ExitStrategy',
        title: 'Exit strategy',
      },
      {
        slug: 'Miscellaneous',
        title: 'Miscellaneous',
      },
    ],
  },
});

export default {
  namespaced: true,
  state() {
    return getInitialActionItemState();
  },
  mutations,
  actions,
  getters,
};
