import { getInitialUserInCreationState, getInitialUserEditState } from './index';

export default {
  setUsers(state, payload) {
    state.users = [];
    state.users = [...state.users, ...payload];
    state.users.find((user) => {
      if (user.id === state.loggedInUser.id) {
        user.loggedInUser = true;
      }
    });
  },
  setLoggedInUser(state, payload) {
    state.loggedInUser = { ...payload };
  },
  addUserInCreation(state) {
    state.userInCreation = { ...getInitialUserInCreationState() };
  },
  patchUserInCreation: function (state, payload) {
    state.userInCreation = { ...state.userInCreation, ...payload };
  },
  setUserInCreationSavingState: function (state, payload) {
    state.userInCreation.savingState = { ...payload };
  },
  setUserDetails: function (state, payload) {
    state.userToEdit = { ...getInitialUserEditState() };
    const activeUser = state.users.find((user) => {
      if (user.id === payload.id) {
        const newData = {
          ...state.userToEdit,
          ...payload,
        };
        return Object.assign(user, { ...user, ...newData });
      }
    });
    state.userToEdit = { ...state.userToEdit, ...activeUser };

    if (state.userToEdit.hasProxy && state.userToEdit.hasProxy.length) {
      const firstProxy = state.userToEdit.hasProxy[0];
      state.userToEdit.proxySettingsToSave.hasProxy = true;
      state.userToEdit.proxySettingsToSave.proxyUser = {
        id: firstProxy.id,
        firstName: firstProxy.firstName,
        lastName: firstProxy.lastName,
      };
      state.userToEdit.proxySettingsToSave.periodOfTime = [firstProxy.fromDate, firstProxy.toDate];
    }
  },
  patchUserActiveState: function (state, payload) {
    state.users.find((user) => {
      if (user.id === payload.id) {
        user.active = payload.value;
      }
    });
  },
  patchUserToEdit: function (state, payload) {
    const newFieldValue = {
      [payload.fieldName]: payload.value,
    };
    state.userToEdit = { ...state.userToEdit, ...newFieldValue };
  },
  setUserToEditSavingState: function (state, payload) {
    state.userToEdit.savingState = { ...payload };
  },
  patchUserDetail: function (state, payload) {
    state.users.find((user) => {
      if (user.id === payload.id) {
        Object.keys(user).find((key) => {
          if (payload[key] && user[key] !== payload[key]) {
            user[key] = payload[key];
          }
        });
      }
    });
  },
  setProxyToSave: function (state, payload) {
    const { fieldName, value } = payload;
    Object.keys(state.userToEdit.proxySettingsToSave).forEach((key) => {
      if (key === fieldName) {
        state.userToEdit.proxySettingsToSave[key] = value;
      }
    });
  },
  setLoggedInUserFilter: function (state, payload) {
    console.log(300, state, payload);
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
};
