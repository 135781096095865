import Vue from 'vue';
import Vuex from 'vuex';

import accountsModule from './modules/accounts/index';
import actionItemsModule from './modules/actionItems/index';
import accountHistoryModule from './modules/accountHistory/index';
import userHistoryModule from './modules/userHistory/index';
import accountCommentsModule from './modules/accountComments/index';
import actionItemCommentsModule from './modules/actionItemComments/index';
import insightsModule from './modules/insights/index';
import usersModule from './modules/users/index';
import zonesModule from './enums/zones/index';
import riskLevelsModule from './enums/riskLevels/index';
import issuesModule from './enums/issues/index';
import customerTemperaturesModule from './enums/customerTemperatures/index';
import labProductsModule from './enums/labProducts/index';
import userRolesModule from './enums/userRoles/index';
import notifications from './notifications/index';
import filters from './filters/index';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcSettings = {
  authority: process.env.VUE_APP_AUTHORITY,
  client_id: process.env.VUE_APP_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback`,
  silent_redirect_uri: `${window.location.origin}`,
  post_logout_redirect_uri: window.location.origin,
  response_type: 'code',
  scope: 'openid profile email',
  ignoreNonceAfterRefresh: true,
};

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    accounts: accountsModule,
    users: usersModule,
    zones: zonesModule,
    riskLevels: riskLevelsModule,
    customerTemperatures: customerTemperaturesModule,
    issues: issuesModule,
    labProducts: labProductsModule,
    userRoles: userRolesModule,
    notifications,
    filters,
    actionItems: actionItemsModule,
    accountComments: accountCommentsModule,
    actionItemComments: actionItemCommentsModule,
    accountHistory: accountHistoryModule,
    userHistory: userHistoryModule,
    insights: insightsModule,
    oidcStore: vuexOidcCreateStoreModule(
      // oidcSettings,
      // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
      // spread your oidcSettings and define a userStore of your choice
      {
        ...oidcSettings,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      },
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => console.log('OIDC user is loaded:', user),
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => console.log('Access token did expire'),
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
        oidcError: (payload) => console.log('OIDC error', payload),
        automaticSilentRenewError: (payload) =>
          console.log('OIDC automaticSilentRenewError', payload),
      }
    ),
  },
});

export default store;
