<template>
  <div>
    <div class="cra-fixed-header">
      <the-header />
      <the-filterbar />
    </div>
    <v-container fluid class="mt-12 cra-max-content cra-content">
      <v-row class="mb-0" justify="space-between" align="center">
        <v-col cols="12" md="9">
          <h2 class="h2-display">Active accounts ({{ numActiveAccounts }})</h2>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="d-flex justify-end">
          <base-button
            v-if="!!getLoggedInUser && !PermissionService.hasReadOnlyRestrict()"
            mode="primary"
            icon="mdi-plus"
            :icon-size="'22px'"
            @click="createAccount"
          >
            Add account
          </base-button>
        </v-col>
      </v-row>
      <v-row v-if="accounts.length" v-masonry transition-duration="0.3s" class="mb-16 pb-8">
        <v-col
          v-for="account in accounts"
          :key="account.id"
          v-masonry-tile
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          {{ account.issues }}
          <account-card
            :id="account.id"
            :title="account.name"
            :zone="account.zone"
            :states="account.states"
            :customer-success-lead="
              account.customerSuccessLead
                ? account.customerSuccessLead
                : { id: '2434675', lastName: 'set in dev', firstName: 'Not' }
            "
            :customer-temperature="account.customerTemperature"
            :risk-level="account.riskLevel"
            :last-update="account.lastUpdate"
            :sum-of-revenue="account.sumOfRevenue"
            :open-action-items="account.openActionItems"
            :sold-to="account.contract.soldTo"
            :ship-to="account.contract.shipTo"
            :issues="account.accountIssues"
            :rfp-due-date="account.rfpDueDate"
            @click="showAccountDetail(account.id)"
          />
        </v-col>
        <v-col>
          <div class="pagination">
            <router-link
              v-for="page in pages"
              :key="page"
              :to="{ path: $route.path, query: { ...$route.query, page: page } }"
              :class="{ active: activePage === page || (!activePage && page === 1) }"
              >{{ page }}</router-link
            >
          </div>
        </v-col>
      </v-row>
      <v-row v-else class="mb-16 pb-8">
        <v-col cols="12" sm="6" md="4" lg="3"
          ><v-card class="cra-card-add" min-height="400" outlined rounded @click="createAccount">
            <v-card-text><h3>Add account</h3></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <router-view />
    <the-footer />
  </div>
</template>
<script>
import TheHeader from '../../components/layout/TheHeader';
import TheFooter from '../../components/layout/TheFooter';
import BaseButton from '../../components/ui/BaseButton';
import TheFilterbar from '../../components/layout/TheFilterbar';
import AccountCard from '../../components/accounts/AccountCard';
import { mapGetters } from 'vuex';
import PermissionService from '../../services/PermisssionService';

export default {
  name: 'AccountOverview',
  components: { AccountCard, TheFilterbar, BaseButton, TheFooter, TheHeader },
  data() {
    return {
      isLoading: false,
      PermissionService,
      activePage: 1,
      pages: 1,
      numActiveAccounts: 0,
      searchTermTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'accounts/accounts',
      getLoggedInUser: 'users/getLoggedInUser',
      hasAccounts: 'accounts/hasAccounts',
      zone: 'filters/getActiveZones',
      riskLevel: 'filters/getActiveRiskLevels',
      customerTemperature: 'filters/getActiveCustomerTemperatures',
      myAccounts: 'filters/getActiveMyAccounts',
      searchTerm: 'filters/getSearchTerm',
    }),
  },
  watch: {
    accounts() {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry();
      }
    },
    zone() {
      this.fetchAccounts();
    },
    riskLevel() {
      this.fetchAccounts();
    },
    customerTemperature() {
      this.fetchAccounts();
    },
    searchTerm() {
      clearTimeout(this.searchTermTimeout);
      this.searchTermTimeout = setTimeout(() => {
        this.fetchAccounts();
      }, 1000);
    },
    myAccounts() {
      this.fetchAccounts();
    },
  },
  created() {
    this.isLoading = true;
    this.numActiveAccounts = this.$store.state.accounts.numAccounts;
    (this.pages = Array.from(
      { length: Math.ceil(this.numActiveAccounts / 20) },
      (_, index) => index + 1
    )),
      (this.activePage = this.$route.query.page ? parseInt(this.$route.query.page) : undefined);
    this.loadAccountResources();
    this.isLoading = false;
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.page) {
      const newPage = parseInt(to.query.page) || 1;
      this.activePage = newPage;

      this.$store
        .dispatch('accounts/loadAccounts', {
          page: newPage,
          type: ['Active', 'Submitted'],
          zone: this.zone,
          riskLevel: this.riskLevel,
          customerTemperature: this.customerTemperature,
        })
        .then(() => {
          if (typeof this.$redrawVueMasonry === 'function') {
            this.$redrawVueMasonry();
          }
          this.scrollToTop();
          next();
        })
        .catch((error) => {
          console.error('Error loading accounts:', error);
          next();
        });
    } else {
      next();
    }
  },
  beforeUpdate() {
    this.numActiveAccounts = this.$store.state.accounts.numAccounts;
  },
  methods: {
    scrollToTop() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
    createAccount() {
      this.$router.push({ path: '/accounts/create' });
    },
    showAccountDetail(id) {
      this.$router.push({
        path: `/accounts/${id}`,
      });
    },
    loadAccountResources() {
      try {
        this.$store.dispatch('zones/loadZones');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('riskLevels/loadRiskLevels');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('customerTemperatures/loadCustomerTemperatures');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('issues/loadIssues');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('labProducts/loadLabProducts');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      try {
        this.$store.dispatch('userRoles/loadUserRoles');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
    },
    fetchAccounts() {
      this.isLoading = true;
      this.$store
        .dispatch('accounts/loadAccounts', {
          page: this.page,
          type: ['Active', 'Submitted'],
          zone: this.zone,
          riskLevel: this.riskLevel,
          customerTemperature: this.customerTemperature,
          myAccounts: this.myAccounts,
          query: this.searchTerm,
        })
        .then(() => {
          if (typeof this.$redrawVueMasonry === 'function') {
            this.$redrawVueMasonry();
          }

          (this.pages = Array.from(
            { length: Math.ceil(this.numActiveAccounts / 20) },
            (_, index) => index + 1
          )),
            (this.activePage = this.$route.query.page
              ? parseInt(this.$route.query.page)
              : undefined);

          this.isLoading = false;
          this.scrollToTop();
        })
        .catch((error) => {
          console.error('Error loading accounts:', error);
        });
    },
  },
};
</script>
