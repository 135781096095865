import axios from 'axios';
import store from '../store/index';

axios.interceptors.request.use(
  async (requestConfig) => {
    if (!requestConfig.url.includes('.s3.')) {
      const accessToken = store.state.oidcStore.id_token;
      requestConfig.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
    }
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 ||
        (error.response.status === 403 && error.response.data.code !== 'E403-002'))
    ) {
      if (localStorage.getItem('currentUser')) {
        localStorage.removeItem('currentUser');
      }
      // eslint-disable-next-line no-restricted-globals
      location.href = '/unauthorized';
    }
    return Promise.reject(error);
  }
);

const ApiService = {
  approveAccount(link) {
    return axios.patch(link).catch((e) => {
      console.log(e);
    });
  },
  denyAccount(id, payload) {
    return axios.patch('/account-approval/' + id + '/deny', payload).catch((e) => {
      console.log(e);
    });
  },
  approveAccountCustomer(link) {
    return axios.patch(link).catch((e) => {
      console.log(e);
    });
  },
  denyAccountCustomer(id, payload) {
    return axios.patch('/account-approval/' + id + '/customer/reject', payload).catch((e) => {
      console.log(e);
    });
  },
  fetchAllAccounts(page, type, zone, riskLevel, customerTemperature, query, myAccounts) {
    const parameters = {
      page: page ? page : 1,
    };

    if (type && type.length) {
      parameters['type'] = Array.isArray(type) ? type.join(',') : type;
    }

    if (zone && zone.length) {
      parameters['zone'] = Array.isArray(zone) ? zone.join(',') : zone;
    }

    if (riskLevel && riskLevel.length) {
      parameters['riskLevel'] = Array.isArray(riskLevel) ? riskLevel.join(',') : riskLevel;
    }

    if (customerTemperature && customerTemperature.length) {
      parameters['customerTemperature'] = Array.isArray(customerTemperature)
        ? customerTemperature.join(',')
        : customerTemperature;
    }

    if (query && query.length) {
      parameters['query'] = query;
    }

    if (myAccounts) {
      parameters['myAccounts'] = myAccounts;
    }

    return axios.get('/accounts', { params: parameters });
  },
  fetchAccountDetails(id) {
    return axios.get(`/accounts/${id}`);
  },
  fetchActionItems(id) {
    return axios.get('/actionItem/accounts/' + id + '/items');
  },
  fetchAccountComments(id) {
    return axios.get('/comments/accounts/' + id);
  },
  fetchAccountHistory(id) {
    return axios.get('/event/accounts/' + id + '/history');
  },
  fetchActionItemComments(id) {
    return axios.get('/comments/items/' + id);
  },
  fetchAllUsers() {
    return axios.get('/users');
  },
  fetchUserDetails(id) {
    return axios.get('/users/id/' + id);
  },
  fetchLoggedInUser() {
    return axios.get('/users/me');
  },
  fetchUserHistory(id) {
    return axios.get('/event/users/' + id + '/history');
  },
  fetchZones() {
    return axios.get('/accounts/zones');
  },
  fetchRiskLevels() {
    return axios.get('/accounts/risklevel');
  },
  fetchCustomerTemperatures() {
    return axios.get('/accounts/customertemperature');
  },
  fetchIssues() {
    return axios.get('/accounts/issues');
  },
  fetchLabProducts() {
    return axios.get('/accounts/labproducts');
  },
  fetchUserRoles() {
    return axios.get('/users/roles');
  },
  fetchAccountApprovers(id) {
    return axios.get('/account-approval/' + id + '/approvers');
  },
  fetchAccountCustomerApprovers(id) {
    return axios.get('/account-approval/' + id + '/customer/approvers');
  },
  createAccount(account) {
    return axios.post('/accounts', account).catch((error) => {
      return Promise.reject(error.response.data.parameters);
    });
  },
  createUser(user) {
    return axios.post('/users', user).catch((error) => {
      return Promise.reject(error.response.data.parameters);
    });
  },
  updateUser(user) {
    return axios.put(`/users/email/${encodeURIComponent(user.sortKey)}`, user).catch((error) => {
      return Promise.reject(error.response.data.parameters);
    });
  },
  createActionItem(accountId, actionItem) {
    return axios.post('/actionItem/accounts/' + accountId + '/items', actionItem);
  },
  createAccountComment(accountId, comment) {
    return axios.post('/comments/accounts/' + accountId, comment);
  },
  createActionItemComment(actionItemId, comment) {
    return axios.post('/comments/items/' + actionItemId, comment);
  },
  saveAccountField(accountId, field) {
    return axios.put('/accounts/' + accountId, field);
  },
  saveAccountCustomerParameters(accountId, data) {
    return axios.put('/accounts/' + accountId + '/customer', data).catch((error) => {
      return error.response.data;
    });
  },
  saveUser(userId, field) {
    delete field.isProxy;
    delete field.hasProxy;
    return axios.put('/users/id/' + userId, field);
  },
  setLoggedUserFilters(field) {
    return axios.put('/users/filter', field);
  },
  archiveUser(userId) {
    return axios.patch('/users/archive/' + userId);
  },
  activateUser(userId) {
    return axios.patch('/users/active/' + userId);
  },
  uploadAccountFile(_accountId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.get(`files/${_accountId}/${file.name}/upload`).then((res) => {
      return axios.put(res.data, formData).then(() => {
        const accountFilePayload = {
          parent_entity_id: _accountId,
          name: file.name,
        };
        return axios.post('files/accounts', accountFilePayload);
      });
    });
  },
  deleteAccountFile(parentEntityId, fileName) {
    return axios.delete('/files/' + parentEntityId + '/' + fileName);
  },
  downloadAccountFile(parentEntityId, fileName) {
    return axios.get('/files/' + parentEntityId + '/' + fileName + '/download').then((res) => {
      const downloadUrl = res.data;
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = downloadUrl;

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
  saveActionItemField(accountId, actionItemId, field) {
    return axios.put('/actionItem/accounts/' + accountId + '/items/' + actionItemId, field);
  },
  markActionItemAsResolved(accountId, actionItemId) {
    return axios.patch(
      '/actionItem/accounts/' + accountId + '/items/' + actionItemId + '/state/resolved'
    );
  },
  uploadActionItemFile(parentEntityId, file) {
    const formData = new FormData();
    formData.append('file', file);

    axios
      .get('/files/' + parentEntityId + '/' + file.name + '/upload')
      .then((res) => {
        const url = res.data;
        axios
          .put(url, formData)
          .then(() => {
            const actionItemUpdate = {
              parent_entity_id: parentEntityId,
              name: file.name,
            };
            return axios.post('files/items', actionItemUpdate);
          })
          .catch((err) => console.log('Failed to upload file', err));
      })
      .catch((err) => console.log('Failed to get signed url', err));
  },
  deleteActionItemFile(parentEntityId, fileName) {
    return axios.delete('/files/' + parentEntityId + '/' + fileName);
  },
  downloadActionItemFile(parentEntityId, fileName) {
    return axios.get('/files/' + parentEntityId + '/' + fileName + '/download').then((res) => {
      const downloadUrl = res.data;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
  markAccountForDeletion(id) {
    return axios.patch('/accounts/' + id + '/delete');
  },
  patchAccountType(id, type) {
    return axios.patch('/accounts/' + id + '/type/' + type);
  },
  patchAccountState(id, state, data) {
    return axios.patch('/accounts/' + id + '/state/' + state, data);
  },
  downloadReport() {
    return axios.get('/report').then((response) => {
      const downloadUrl = response.data;
      const link = document.createElement('a');
      link.href = downloadUrl;

      let excelFileName = 'nam-at-risk-accounts-app.xlsx';

      link.setAttribute('download', excelFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
  fetchInsights(queryParam) {
    return axios.get('/dashboard' + queryParam);
  },
  saveProxy(userId, payload) {
    return axios.post('/users/proxy/' + userId, payload);
  },
  deleteProxy(userId, proxyRuleId) {
    return axios.delete('/users/proxy/' + userId + '/rule/' + proxyRuleId);
  },
};

export default ApiService;
