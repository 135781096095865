import ApiService from '../../../services/ApiService';
import { AccountStates, AccountTypes } from './index';

export default {
  async loadAccounts(context, options) {
    const page = options.page || 1;
    const type = Array.isArray(options.type)
      ? options.type
      : options.type
      ? [options.type]
      : ['Active', 'Submitted'];
    const zone = options.zone || '';
    const riskLevel = options.riskLevel || '';
    const customerTemperature = options.customerTemperature || '';
    const query = options.query || '';
    const myAccounts = options.myAccounts || false;

    const response = await ApiService.fetchAllAccounts(
      page,
      type,
      zone,
      riskLevel,
      customerTemperature,
      query,
      myAccounts
    );
    if (!response.data) {
      return;
    }
    const accountsResponseList = response.data.result;
    const accounts = [];
    for (const key of accountsResponseList) {
      const account = {
        id: key.id,
        name: key.name,
        city: key.city,
        revenue: key.revenue,
        riskLevel: key.riskLevel,
        customerTemperature: key.customerTemperature,
        customerSuccessLead: key.coreTeam.CustomerSuccessLead,
        zone: key.zone,
        states: key.states,
        openActionItems: key.openActionItems,
        lastUpdate: key.lastUpdate,
        accountIssues: key.accountIssues,
        contract: key.contract,
        sumOfRevenue: key.sumOfRevenue,
        type: key.type,
        rfpDueDate: key.rfpDueDate,
        additionalLink: key.additionalLink,
        responsibleMember: key.responsibleMember,
      };
      account.lastUpdate = new Date(account.lastUpdate).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      });
      accounts.push(account);
    }
    context.commit('setAccounts', accounts);
    context.commit('setNumAccounts', response.data.count);
  },
  async createAccount(context) {
    const accountToSave = { ...context.state.accountInCreation };

    const accountToSaveContract = { ...accountToSave.contract };

    accountToSaveContract.endDate = accountToSaveContract.endDate.toISOString().substr(0, 10);
    if (accountToSaveContract.goLive) {
      accountToSaveContract.goLive = accountToSaveContract.goLive.toISOString().substr(0, 10);
    }

    accountToSave.contract = { ...accountToSaveContract };

    Object.keys(accountToSave.coreTeam).forEach((key) => {
      accountToSave.coreTeam[key] = accountToSave.coreTeam[key].id;
    });

    let files = [];
    accountToSave.files.forEach((file) => {
      files.push(file);
    });

    delete accountToSave.files;
    delete accountToSave.savingState;

    context.commit('setAccountInCreationSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });

    try {
      const response = await ApiService.createAccount(accountToSave);
      files.forEach((file) => {
        context.dispatch('uploadFile', {
          id: response.data,
          file,
        });
      });
      context.commit('setAccountInCreationSavingState', {
        isSaving: false,
        type: 'success',
        message:
          'The account submit was successful - the corresponding approval process has been started.',
      });
    } catch (e) {
      let errorMessage = '';
      Object.keys(e).forEach((key) => {
        errorMessage += e[key] + '. ';
      });
      context.commit('setAccountInCreationSavingState', {
        isSaving: false,
        type: 'error',
        message: 'An error occurred while creating this account. Please try again. ' + errorMessage,
      });
    }
  },
  async loadAccountDetails(context, payload) {
    try {
      const responseDetails = await ApiService.fetchAccountDetails(payload);
      let accountToSet = {
        ...responseDetails.data,
        id: payload,
      };
      if (accountToSet.states.includes(AccountStates.pendingApproval)) {
        const responseApprovers = await ApiService.fetchAccountApprovers(accountToSet.id);
        const approversToSet = {
          accountApprovers: responseApprovers.data,
        };
        accountToSet = {
          ...accountToSet,
          ...approversToSet,
        };
      }
      if (accountToSet.states.includes(AccountStates.pendingChange)) {
        const responseCustomerApprovers = await ApiService.fetchAccountCustomerApprovers(
          accountToSet.id
        );
        const customerApproversToSet = {
          accountCustomerApprovers: responseCustomerApprovers.data,
        };
        accountToSet = {
          ...accountToSet,
          ...customerApproversToSet,
        };
      }
      context.commit('setAccountDetails', accountToSet);
    } catch (e) {
      console.log('error load account detail', e);
    }
  },

  setAccountField(context, payload) {
    const { fieldName, id, validators, value } = payload;

    context.commit('patchAccountToEdit', { fieldName, value });

    const isValid = validators.every((validator) => {
      return validator(context.state.accountToEdit[fieldName]);
    });

    if (!isValid) {
      let oldValue;
      context.state.accounts.find((acc) => {
        if (acc.id === id) {
          Object.keys(acc).find((key) => {
            if (key === fieldName) {
              oldValue = acc[key];
            }
          });
        }
      });
      context.commit('patchAccountToEdit', { fieldName, value: oldValue });
    } else {
      context.commit('patchAccountDetail', payload);
      context.dispatch('saveAccountField', payload).then((r) => {
        console.log('res then', r);
      });
    }
  },
  async saveAccountField(context, payload) {
    const newFieldValue = {
      [payload.fieldName]: payload.value,
    };

    try {
      await ApiService.saveAccountField(payload.id, newFieldValue);
    } catch (e) {
      console.log('error field saved', e);
    }
  },
  async uploadFile(context, payload) {
    try {
      await ApiService.uploadAccountFile(payload.id, payload.file);
      const responseAccountDetails = await ApiService.fetchAccountDetails(payload.id);
      context.commit('addFiles', {
        id: payload.id,
        files: responseAccountDetails.data.files,
      });
    } catch (e) {
      console.log('file upload error', e);
    }
  },
  async deleteFile(context, payload) {
    try {
      await ApiService.deleteAccountFile(payload.id, payload.file.name);
      context.commit('deleteFile', {
        id: payload.id,
        fileId: payload.file.id,
      });
    } catch (e) {
      console.log('file delete error', e);
    }
  },
  async downloadFile(context, payload) {
    try {
      await ApiService.downloadAccountFile(payload.accountId, payload.fileName);
    } catch (e) {
      console.log('error download file', e);
    }
  },
  async saveCustomerParameters(context, payload) {
    const { id, message, riskLevel, customerTemperature } = payload;
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });

    const response = await ApiService.saveAccountCustomerParameters(id, {
      riskLevel,
      customerTemperature,
      comment: message,
    });
    if (response.code) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: response.description,
      });
    } else {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Customer parameters has changed. Please wait for approval',
      });
    }

    context.dispatch('loadAccountDetails', id).then();
    await context.dispatch('accountComments/loadAccountComments', { id }, { root: true });
    context.commit('patchAccountCustomerParameters', { id, riskLevel, customerTemperature });
  },
  setAccountContractField(context, payload) {
    const { fieldName, id, validators, value } = payload;
    context.commit('patchAccountContractToEdit', { fieldName, value });

    const isValid = validators.every((validator) => {
      return validator(context.state.accountToEdit.contract[fieldName]);
    });

    if (!isValid) {
      let oldValue;
      context.state.accounts.find((acc) => {
        if (acc.id === id) {
          Object.keys(acc.contract).find((key) => {
            if (key === fieldName) {
              oldValue = acc.contract[key];
            }
          });
        }
      });
      context.commit('patchAccountContractToEdit', { fieldName, value: oldValue });
    } else {
      context.commit('patchAccountContractDetail', payload);
      context
        .dispatch('saveAccountField', {
          id,
          fieldName: 'contract',
          value: context.state.accountToEdit.contract,
        })
        .then((r) => {
          if (fieldName === 'endDate') {
            context.dispatch('loadAccountDetails', id).then();
          }
          console.log('res then', r);
        });
    }
  },
  setAccountRevenueField(context, payload) {
    const { fieldName, id, value } = payload;
    context.commit('patchAccountRevenueFields', { id, fieldName, value: Number(value) });
    context
      .dispatch('saveAccountField', {
        id,
        fieldName: 'revenue',
        value: context.state.accountToEdit.revenue,
      })
      .then((r) => {
        console.log('res then', r);
      });
  },
  setAccountCoreTeam(context, payload) {
    const { coreTeamMember, role, accountId } = payload;
    const memberData = {
      [role]: coreTeamMember,
    };
    context.commit('patchAccountCoreTeam', { id: accountId, coreTeamMember: memberData, role });
    context.dispatch('saveCoreTeam').then();
  },
  resetCoreTeamOnUpdateZone(context, payload) {
    const coreTeamToEdit = context.getters['getCoreTeamAccountToEdit'];
    const usersByZone = context.rootGetters['users/usersByZone'](payload.zone);
    let coreTeamToSet = {};
    Object.keys(coreTeamToEdit).forEach((key) => {
      const oldMember = usersByZone.find(
        (user) => user.id === coreTeamToEdit[key].id && user.roles.some((role) => role === key)
      );
      if (oldMember) {
        coreTeamToSet = { ...coreTeamToSet, ...{ [key]: oldMember } };
      } else {
        coreTeamToSet = { ...coreTeamToSet, ...{ [key]: '' } };
      }
    });
    context.commit('patchAccountToEdit', { fieldName: 'coreTeam', value: coreTeamToSet });
    context.commit('patchAccountToEdit', { fieldName: 'zone', value: payload.zone });
    context.dispatch('saveCoreTeam').then();
  },
  saveCoreTeam(context) {
    const coreTeamToSave = { ...context.state.accountToEdit.coreTeam };
    Object.keys(coreTeamToSave).forEach((key) => {
      coreTeamToSave[key] = context.state.accountToEdit.coreTeam[key].id;
    });
    context
      .dispatch('saveAccountField', {
        id: context.state.accountToEdit.id,
        fieldName: 'coreTeam',
        value: coreTeamToSave,
      })
      .then((r) => {
        console.log('res then', r);
      });
  },
  async approveAccount(context, link) {
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      await ApiService.approveAccount(link);
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Approval accepted',
      });
      context.dispatch('loadAccountDetails', context.getters['getAccountToEdit'].id).then();
    } catch (e) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Not submitted! ' + e.toString(),
      });
    }
  },
  async denyAccount(context) {
    const accountId = context.getters['getAccountToEdit'].id;
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      await ApiService.denyAccount(accountId, {
        comment: context.rootGetters['accountComments/getCommentInCreation'].comment,
      });
      await context.dispatch(
        'accountComments/loadAccountComments',
        { id: accountId },
        { root: true }
      );
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Deny accepted',
      });
      context.dispatch('loadAccountDetails', accountId).then();
    } catch (e) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Not submitted! ' + e.toString(),
      });
    }
  },
  async approveAccountCustomer(context, link) {
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      await ApiService.approveAccountCustomer(link);
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Approval accepted',
      });
      context.dispatch('loadAccountDetails', context.getters['getAccountToEdit'].id).then();
    } catch (e) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Not submitted! ' + e.toString(),
      });
    }
  },
  async denyAccountCustomer(context) {
    const accountId = context.getters['getAccountToEdit'].id;
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      await ApiService.denyAccountCustomer(accountId, {
        comment: context.rootGetters['accountComments/getCommentInCreation'].comment,
      });
      await context.dispatch(
        'accountComments/loadAccountComments',
        { id: accountId },
        { root: true }
      );
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Deny accepted',
      });
      context.dispatch('loadAccountDetails', accountId).then();
    } catch (e) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Not submitted! ' + e.toString(),
      });
    }
  },
  async moveToWatchlist(context, payload) {
    await ApiService.patchAccountType(payload.id, payload.type);
  },
  async moveToOTR(context, payload) {
    await ApiService.patchAccountType(payload.id, payload.type);
  },
  async moveToDeleted(context, payload) {
    await ApiService.patchAccountType(payload.id, payload.type);
  },
  async setAccountState(context, payload) {
    const { id, stateToChange } = payload;
    let patchData = {};
    let date;
    let path;
    switch (stateToChange) {
      case AccountStates.rfpDue:
        date = context.state.accountToEdit.changingStateFields.date.toISOString().substr(0, 10);
        path = 'rfp-due';
        patchData = {
          rfpDueDate: date,
        };
        context.commit('patchAccountToEdit', {
          fieldName: 'rfpDueDate',
          value: date,
        });
        context.commit('patchAccountDetail', {
          id: context.state.accountToEdit.id,
          fieldName: 'rfpDueDate',
        });
        break;
      case AccountStates.contractRenewed:
        path = 'contract-renewed';
        break;
      case AccountStates.accountLost:
        path = 'account-lost';
        break;
      case AccountStates.stateless:
        path = 'stateless';
        break;
      default:
        path = '';
        break;
    }
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      if (context.rootGetters['accountComments/getCommentInCreation'].comment) {
        await context.dispatch(
          'accountComments/createAccountComment',
          { accountId: id },
          { root: true }
        );
        if (
          context.rootGetters['accountComments/getCommentInCreationSavingState'].type === 'error'
        ) {
          throw context.rootGetters['accountComments/getCommentInCreationSavingState'].message;
        }
      }

      await ApiService.patchAccountState(id, path, patchData);

      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Account state has changed',
      });
      const responseDetails = await ApiService.fetchAccountDetails(id);
      const newStates = responseDetails.data.states;
      context.commit('patchAccountToEdit', { fieldName: 'states', value: newStates });
      context.commit('patchAccountDetail', { id, fieldName: 'states' });
    } catch (e) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Set new state failed! ' + e.toString(),
      });
    }
  },
  async setAccountType(context, payload) {
    const { id, typeToChange } = payload;

    let path;
    let message;
    switch (typeToChange) {
      case AccountTypes.watchlist:
        path = 'watchlist';
        message = 'Account has been moved to watchlist';
        break;
      case AccountTypes.otr:
        path = 'otr';
        message = 'Account has been moved to on the radar';
        break;
      case AccountTypes.archived:
        path = 'archived';
        message = 'Account has been moved to archive';
        break;
      case AccountTypes.active:
        path = 'active';
        message = 'Account has been moved to active';
        break;
      case AccountTypes.deleted:
        path = 'deleted';
        message = 'Account has been moved to deleted';
        break;
      default:
        path = '';
        message = '';
        break;
    }
    context.commit('setAccountToEditSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });
    try {
      if (path === 'deleted') {
        await ApiService.markAccountForDeletion(id);
      } else {
        await ApiService.patchAccountType(id, path);
      }
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'success',
        message: message,
      });
      context.commit('patchAccountToEdit', { fieldName: 'type', value: typeToChange });
      context.commit('patchAccountDetail', { id, fieldName: 'type' });
      context.commit('setNumAccounts', context.state.numAccounts - 1);
    } catch (e) {
      context.commit('setAccountToEditSavingState', {
        isSaving: false,
        type: 'error',
        message: 'Set new type failed! ' + message,
      });
    }
  },
};
