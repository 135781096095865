<template>
  <v-container class="text-center" fill-height style="height: calc(100vh - 58px)">
    <v-row align="center">
      <v-col>
        <h1 class="display-2 primary--text">Whoops</h1>

        <p>You are unauthorized.</p>
        <div class="unauthorized-container">
          <v-btn color="primary" outlined @click="sendMailRequestAccess"> Request Access </v-btn>
          <v-btn color="primary" outlined @click="toHome"> Go to homepage </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Unauthorized',
  methods: {
    sendMailRequestAccess() {
      window.location =
        'mailto:kristina.dudaeva@siemens-healthineers.com?subject=Request for access to NAM at Risk Accounts App';
    },
    toHome() {
      window.sessionStorage.clear();
      this.$router.push('/');
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';
.unauthorized-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
</style>
