import ApiService from '../../../services/ApiService';

export default {
  async loadActionItems(context, payload) {
    const response = await ApiService.fetchActionItems(payload.id);

    if (!response.data) {
      context.commit('setActionItems', []);
      return;
    }
    const itemResponseList = response.data;

    const items = [];

    for (const key in itemResponseList) {
      const item = {
        actualImpact: itemResponseList[key].actualImpact,
        created: itemResponseList[key].created,
        description: itemResponseList[key].description,
        dueDate: itemResponseList[key].dueDate,
        id: itemResponseList[key].id,
        issues: itemResponseList[key].issues,
        labProducts: itemResponseList[key].labProducts,
        number: itemResponseList[key].number,
        plannedImpact: itemResponseList[key].plannedImpact,
        responsibleUser: itemResponseList[key].responsibleMember,
        state: itemResponseList[key].state,
        files: itemResponseList[key].files,
        category: itemResponseList[key].category,
      };

      item.dueDate = new Date(item.dueDate);
      items.push(item);
    }
    context.commit('setActionItems', items);
  },
  async createActionItem(context, payload) {
    const actionItemToSave = { ...context.state.actionItemInCreation };
    actionItemToSave.dueDate = actionItemToSave.dueDate.toISOString().substr(0, 10);
    actionItemToSave.plannedImpact = actionItemToSave.plannedImpact
      ? Number(actionItemToSave.plannedImpact)
      : 0;
    actionItemToSave.actualImpact = actionItemToSave.actualImpact
      ? Number(actionItemToSave.actualImpact)
      : 0;

    let files = [];
    actionItemToSave.files.forEach((file) => {
      files.push(file);
    });

    delete actionItemToSave.files;
    delete actionItemToSave.savingState;
    delete actionItemToSave.isEditing;

    context.commit('setActionItemInCreationSavingState', {
      isSaving: true,
      type: '',
      message: '',
    });

    let itemId = '';

    try {
      const response = await ApiService.createActionItem(payload.accountId, actionItemToSave);
      itemId = response.data.id;
      if (files.length) {
        for (const file of files) {
          await ApiService.uploadActionItemFile(itemId, file);
        }
      }
      context.commit('setActionItemInCreationSavingState', {
        isSaving: false,
        type: 'success',
        message: 'Item saved',
        itemId: itemId,
      });
    } catch (e) {
      context.commit('setActionItemInCreationSavingState', {
        isSaving: false,
        type: 'error',
        message: e.toString(),
        itemId: itemId,
      });
    }
  },
  setActionItemField(context, payload) {
    const { fieldName, actionItemId, validators, value } = payload;
    context.commit('patchActionItemToEdit', { actionItemId, fieldName, value });
    const isValid = validators.every((validator) => {
      return validator(context.state.actionItemToEdit[fieldName]);
    });
    let oldValue;
    context.state.actionItems.find((item) => {
      if (item.id === actionItemId) {
        Object.keys(item).find((key) => {
          if (key === fieldName) {
            oldValue = item[key];
          }
        });
      }
    });
    if (!isValid) {
      context.commit('patchActionItemToEdit', { fieldName, value: oldValue });
    } else {
      if (oldValue !== value) {
        context.commit('patchActionItem', { id: actionItemId, fieldName: fieldName });
        context.dispatch('saveActionItemField', payload).then();
      }
    }
  },
  setActionItemResponsibleUser(context, payload) {
    const { value, actionItemId, accountId } = payload;
    context.commit('patchActionItemResponsibleUser', { id: actionItemId, user: value });
    context
      .dispatch('saveActionItemField', {
        fieldName: 'responsibleUserId',
        accountId,
        actionItemId,
        value: value.id,
      })
      .then((r) => {
        console.log('res then', r);
      });
  },
  async saveActionItemField(context, payload) {
    let value = payload.value;
    if (value instanceof Date) {
      value = value.toISOString().substr(0, 10);
    }
    const newFieldValue = {
      [payload.fieldName]: value,
    };
    try {
      const response = await ApiService.saveActionItemField(
        payload.accountId,
        payload.actionItemId,
        newFieldValue
      );
      return response;
    } catch (e) {
      console.log('error field saved', e);
    }
  },
  async markAsResolved(context, payload) {
    try {
      const response = await ApiService.markActionItemAsResolved(
        payload.accountId,
        payload.actionItemId
      );
      context.commit('patchState', { actionItemId: payload.actionItemId });
      return response;
    } catch (e) {
      console.log('error patch resolved', e);
    }
  },
  async uploadFile(context, payload) {
    context.commit('notifications/setBusy', true, { root: true });
    try {
      await ApiService.uploadActionItemFile(payload.actionItemId, payload.file);
      const responseActionItems = await ApiService.fetchActionItems(payload.accountId);
      const responseList = responseActionItems.data;

      responseList.find((item) => {
        if (item.id === payload.id) {
          context.commit('addFiles', {
            id: payload.id,
            files: item.files,
          });
        }
      });
    } catch (e) {
      context.commit('notifications/setBusy', false, { root: true });
      context.commit(
        'notifications/setSnackbar',
        {
          type: 'error',
          message: e,
          timeout: 6000,
          visible: true,
        },
        { root: true }
      );
      console.log('file upload error', e);
    }
    context.commit('notifications/setBusy', false, { root: true });
  },
  async deleteFile(context, payload) {
    try {
      await ApiService.deleteActionItemFile(payload.actionItemId, payload.file.name);
      context.commit('deleteFile', {
        id: payload.actionItemId,
        fileId: payload.file.id,
      });
    } catch (e) {
      console.log('file delete error', e);
    }
  },
  async downloadFile(context, payload) {
    try {
      await ApiService.downloadActionItemFile(payload.actionItemId, payload.fileName);
    } catch (e) {
      console.log('error download file', e);
    }
  },
};
