import store from '../store/index';
import removeSpaces from '../helpers/removeSpaces';

const PermissionService = {
  isDisabled(rules) {
    return !rules.some((rule) => ruleValidators[rule]());
  },
  isVisible(rules) {
    return rules.some((rule) => ruleValidators[rule]());
  },
  hasCoreTeamRole(role) {
    const coreTeam = store.getters['accounts/getAccountToEdit'].coreTeam;
    const coreTeamRole = Object.keys(coreTeam).find((key) => key === removeSpaces(role));
    if (!coreTeam[coreTeamRole]) {
      return false;
    }
    return store.getters['users/getLoggedInUser'].id === coreTeam[coreTeamRole].id;
  },
  hasUserRole(role) {
    const user = store.getters['users/getLoggedInUser'];
    return user.roles.find((userRole) => removeSpaces(userRole) === removeSpaces(role));
  },
  hasAccountType(type) {
    return type === store.getters['accounts/getAccountToEdit'].type;
  },
  hasProxyRole(role) {
    const user = store.getters['users/getLoggedInUser'];
    if (!user.hasProxy) {
      return false;
    }
    return user.hasProxy[0].roles.find((userRole) => removeSpaces(userRole) === removeSpaces(role));
  },
  isAdmin() {
    return store.getters['users/getLoggedInUser'].hasAdminRights;
  },
  hasReadOnlyRestrict() {
    return store.getters['users/getLoggedInUser'].readOnly;
  },
};

const ruleValidators = {
  isCurrentUser() {
    return store.getters['users/getLoggedInUser'].id === store.getters['users/getUserToEdit'].id;
  },
  isAdmin() {
    return store.getters['users/getLoggedInUser'].hasAdminRights;
  },
};

export default PermissionService;
