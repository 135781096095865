<template>
  <v-expansion-panel
    :ref="actionItemId"
    :class="[
      'mb-6',
      'cra-action-item',
      isExpired(getActionItem(actionItemId).dueDate) && state !== 'resolved'
        ? 'marker-due-date'
        : '',
    ]"
  >
    <v-expansion-panel-header
      ref="expansion-header"
      class="py-0 cra-action-item-header"
      @click="setPanelState"
      @keyup.space.prevent
    >
      <div class="cra-action-item-header-grid">
        <div class="cra-action-item-number">
          <h2 class="cra-action-number my-0">{{ number }}</h2>
        </div>
        <div class="cra-action-item-description">
          <div v-if="!getActionItem(actionItemId).isEditing">
            <h6 class="my-0">
              {{ getActionItem(actionItemId).description }}
            </h6>
          </div>
          <div v-else @click.stop="handleFieldClick('input-title')">
            <v-textarea
              ref="input-title"
              v-model="fieldsToValidate.description.value"
              dense
              auto-grow
              rows="1"
              :disabled="state === 'resolved'"
              :readonly="readOnly"
              hide-details
              class="h6"
              @change="(value) => updateField({ fieldName: 'description', value })"
              @click.stop=""
              @click.native.stop
            />
          </div>
        </div>
        <div class="cra-action-item-category">
          <div
            v-if="!getActionItem(actionItemId).isEditing || state === 'resolved' || readOnly"
          ></div>
          <div v-if="!getActionItem(actionItemId).isEditing || state === 'resolved' || readOnly">
            <div v-if="getActionItem(actionItemId).category">
              <v-chip
                v-for="category in getActionItem(actionItemId).category"
                :key="category"
                class="mr-2 mb-2"
                >{{ getActionItemCategoryTitle(category) }}</v-chip
              >
            </div>
            <p v-else class="cra-p-no-item-set">No category set</p>
          </div>
          <div v-else>
            <chips-select
              :title="'Categories'"
              :selected="
                getActionItemSelectedCategoriesForSelect(getActionItem(actionItemId).category) || []
              "
              :items="getActionItemCategoriesForSelect"
              @click.stop=""
              @click.native.stop
              @change-selected="(value) => updateField({ fieldName: 'category', value })"
            ></chips-select>
          </div>
        </div>
        <div class="cra-action-item-issues">
          <div v-if="!getActionItem(actionItemId).isEditing || state === 'resolved' || readOnly">
            <v-chip
              v-for="issue in getActionItem(actionItemId).issues"
              :key="issue"
              class="mr-2 mb-2"
              >{{ issue }}</v-chip
            >
          </div>
          <div v-else>
            <chips-select
              :title="'Item issues'"
              :selected="getActionItem(actionItemId).issues"
              :items="getIssues"
              @click.stop=""
              @click.native.stop
              @change-selected="(value) => updateField({ fieldName: 'issues', value })"
            ></chips-select>
          </div>
        </div>
        <div class="cra-action-item-labels">
          <div v-if="!getActionItem(actionItemId).isEditing || state === 'resolved' || readOnly">
            <v-chip
              v-for="product in getActionItem(actionItemId).labProducts"
              :key="product"
              class="mr-2 mb-2"
              >{{ product }}</v-chip
            >
          </div>
          <div v-else>
            <chips-select
              :title="'Lab products'"
              :selected="getActionItem(actionItemId).labProducts"
              :items="getLabProducts"
              @click.stop=""
              @click.native.stop
              @change-selected="(value) => updateField({ fieldName: 'labProducts', value })"
            ></chips-select>
          </div>
        </div>
        <div class="cra-action-item-date">
          <div v-if="!getActionItem(actionItemId).isEditing">
            <h6 :class="[dueDateFieldClass(getActionItem(actionItemId).dueDate), 'h6-normal']">
              {{ dueDateAsString }}
            </h6>
          </div>
          <div v-else>
            <base-date-picker
              :date="getActionItem(actionItemId).dueDate"
              :marker-class="dueDateFieldClass(getActionItem(actionItemId).dueDate)"
              :disabled="
                state === 'resolved' || isExpired(getActionItem(actionItemId).dueDate) || readOnly
              "
              label="Due date"
              :filled="false"
              :append-icon="''"
              :nudge-right="'-100'"
              @change-field="(date) => updateField({ fieldName: 'dueDate', value: date })"
              @click.stop=""
              @click.native.stop
            />
          </div>
        </div>
        <div class="cra-action-item-responsible">
          <div v-if="!getActionItem(actionItemId).isEditing || state === 'resolved' || readOnly">
            <v-avatar class="cra-avatar" size="40">
              <span>{{ responsibleUser.initials }}</span>
            </v-avatar>
          </div>
          <div v-else>
            <p class="p-tiny mb-2">
              <strong>Responsible</strong>
            </p>
            <user-select
              :items="userList"
              :role-name="'Responsible'"
              :narrow="true"
              :preselected="responsibleUser"
              @click.stop=""
              @click.native.stop
              @change-user="(value) => updateResponsibleUser(value)"
            />
          </div>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content ref="expansion-content">
      <div class="cra-action-item-content-grid">
        <div class="cra-action-item-number"></div>
        <div class="cra-action-item-upload">
          <base-file-input
            :label="'Document upload'"
            :files="getActionItem(actionItemId).files"
            :is-editable="state !== 'resolved' && !readOnly"
            @add-files="(value) => addFiles({ fieldName: 'files', value })"
            @remove-files="(value) => removeFiles({ fieldName: 'files', value })"
            @open-file="(value) => openFile(value)"
          ></base-file-input>
        </div>
        <div class="cra-action-item-planned-impact">
          <v-currency-field
            label="Planned cost $"
            :disabled="state === 'resolved' || readOnly"
            :value="getActionItem(actionItemId).plannedImpact"
            hide-details
            @change="(value) => updateCurrencyField({ fieldName: 'plannedImpact', value })"
          />
        </div>
        <div class="cra-action-item-actual-impact">
          <v-currency-field
            label="Actual cost $"
            :disabled="readOnly"
            :value="getActionItem(actionItemId).actualImpact"
            hide-details
            @change="(value) => updateCurrencyField({ fieldName: 'actualImpact', value })"
          />
        </div>
      </div>
      <v-divider class="pb-8" />
      <action-item-comments
        v-if="isComments"
        :action-item-id="actionItemId"
        :comment-list="comments"
        :is-add-comment="state !== 'resolved' && !readOnly"
      ></action-item-comments>
      <v-divider v-if="state !== 'resolved' && !readOnly" class="pb-8 mt-8" />
      <v-container v-if="state !== 'resolved' && !readOnly" fluid class="pb-8 px-0">
        <v-col cols="12" style="text-align: right" class="pr-0">
          <base-button
            mode="secondary"
            class="cra-button"
            icon="mdi-check-bold"
            :icon-color="'primary'"
            @click="startResolvedWorkflow()"
          >
            Mark action item as resolved
          </base-button>
        </v-col>
      </v-container>
      <marker-hint-dialog
        v-if="isDialogOn"
        @cancel="cancelSetMarker"
        @accept="acceptSetMarker"
      ></marker-hint-dialog>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapGetters } from 'vuex';
import ChipsSelect from '../../ui/ChipsSelect';
import UserSelect from '../../ui/UserSelect';
import BaseDatePicker from '../../ui/BaseDatePicker';
import BaseButton from '../../ui/BaseButton';
import BaseFileInput from '../../ui/BaseFileInput';
import ValidationService from '../../../services/ValidationService';
import getInitials from '../../../helpers/getInitials';
import getDateAsString from '../../../helpers/getDateAsString';
import isExpired from '../../../helpers/isExpired';
import MarkerHintDialog from './MarkerHintDialog';
import ActionItemComments from '../actionItemComments/ActionItemComments';

export default {
  name: 'ActionItem',
  emits: ['change-state'],
  components: {
    ActionItemComments,
    MarkerHintDialog,
    ChipsSelect,
    UserSelect,
    BaseFileInput,
    BaseDatePicker,
    BaseButton,
  },
  props: {
    number: {
      type: Number,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
    actionItemId: {
      type: String,
      required: true,
    },
    userList: {
      type: Array,
      required: false,
      default: () => {},
    },
    state: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      item: Object,
      fieldsToValidate: {
        description: { value: '', validators: [ValidationService.hasContent] },
        category: { value: '', validators: [ValidationService.hasContent] },
        issues: { value: [], validators: [ValidationService.hasContent] },
        labProducts: { value: [], validators: [ValidationService.hasContent] },
        dueDate: { value: Date, validators: [ValidationService.hasContent] },
        actualImpact: { value: '', validators: [] },
        plannedImpact: { value: '', validators: [] },
      },
      responsibleUser: Object,
      files: { value: [], validators: [ValidationService.hasContent] },
      isDialogOn: false,
      isComments: false,
      comments: Object,
      isExpired: isExpired,
      categoriesToSelect: ['Root cause analysis', 'System stability', 'Parts availability'],
    };
  },
  computed: {
    ...mapGetters({
      getActionItem: 'actionItems/getActionItem',
      getActionItemsEdits: 'actionItems/getActionItemsEdits',
      getActionItemToEdit: 'actionItems/getActionItemToEdit',
      getActionItemFiles: 'actionItems/getActionItemFiles',
      getActionItemCategoriesForSelect: 'actionItems/getActionItemCategoriesForSelect',
      getActionItemSelectedCategoriesForSelect:
        'actionItems/getActionItemSelectedCategoriesForSelect',
      getActionItemCategoryTitle: 'actionItems/getActionItemCategoryTitle',
      getIssues: 'issues/issues',
      getLabProducts: 'labProducts/labProducts',
      getUserById: 'users/userById',
      getComments: 'actionItemComments/getComments',
    }),
    dueDateAsString() {
      return getDateAsString(
        this.getActionItem(this.actionItemId).dueDate.toISOString().substr(0, 10)
      );
    },
  },
  watch: {
    getComments() {
      this.comments = { ...this.getComments };
    },
  },
  mounted() {
    this.setSelectedResponsibleUser();
    if (this.$route.hash) {
      const hashId = this.$route.hash.slice(1, this.$route.hash.length);
      if (hashId === this.actionItemId) {
        this.$refs['expansion-header'].$el.click();
      }
    }
  },
  methods: {
    loadFormFields() {
      this.item = this.getActionItem(this.actionItemId); //todo delete
      Object.keys(this.fieldsToValidate).find((key) => {
        if (key === 'category' && this.getActionItemToEdit[key]) {
          this.fieldsToValidate[key].value = this.getActionItemCategoryTitle(
            this.getActionItemToEdit[key]
          );
        } else {
          this.fieldsToValidate[key].value = this.getActionItemToEdit[key];
        }
      });
    },
    setPanelState() {
      this.$store.commit('actionItems/toggleActionItemToEdit', {
        id: this.actionItemId,
        isEditing: !this.getActionItem(this.actionItemId).isEditing,
      });
      if (this.getActionItem(this.actionItemId).isEditing) {
        this.$nextTick(() => {
          this.$store.commit('actionItems/setActionItemToEdit', {
            id: this.actionItemId,
          });
          this.loadFormFields();
          this.loadFiles();
          this.loadComments();
        });
      }
    },
    loadComments() {
      this.$store.commit('notifications/setBusy', true);
      this.$store
        .dispatch('actionItemComments/loadActionItemComments', { id: this.actionItemId })
        .then(() => {
          this.comments = { ...this.getComments };
          this.isComments = true;
          this.$store.commit('notifications/setBusy', false);
        });
    },
    loadFiles() {
      let files = [];
      const loge = this.getActionItemFiles(this.actionItemId);

      loge.forEach((file) => {
        files.push(file);
      });
      this.files.value = files;
    },
    handleFieldClick(field) {
      this.$nextTick(() => {
        this.$refs[field][0].focus();
      });
    },
    updateFormField(fieldName) {
      this.fieldsToValidate[fieldName].value =
        fieldName === 'category'
          ? this.getActionItemCategoryTitle(this.getActionItemToEdit[fieldName])
          : this.getActionItemToEdit[fieldName];
    },
    updateField(data) {
      this.$store.dispatch('actionItems/setActionItemField', {
        fieldName: data.fieldName,
        value: data.value,
        actionItemId: this.actionItemId,
        accountId: this.accountId,
        validators: this.fieldsToValidate[data.fieldName].validators,
      });
      this.updateFormField(data.fieldName);
    },
    updateCurrencyField(data) {
      if (isNaN(data.value)) {
        return false;
      }
      this.updateField(data);
    },
    updateResponsibleUser(value) {
      const user = this.getUserById(value.id);
      this.$store
        .dispatch('actionItems/setActionItemResponsibleUser', {
          value: user,
          actionItemId: this.actionItemId,
          accountId: this.accountId,
        })
        .then(() => this.setSelectedResponsibleUser());
    },
    setSelectedResponsibleUser() {
      const responsibleUser = this.getActionItem(this.actionItemId).responsibleUser;
      if (responsibleUser === null || responsibleUser === undefined) {
        return null;
      }
      this.responsibleUser = {
        name: responsibleUser.firstName + ' ' + responsibleUser.lastName,
        id: responsibleUser.id,
        initials: getInitials(responsibleUser.firstName, responsibleUser.lastName),
      };
    },
    addFiles(data) {
      const files = data.value;
      files.forEach((file) => {
        this.$store.dispatch('actionItems/uploadFile', {
          accountId: this.accountId,
          actionItemId: this.actionItemId,
          file,
        });
      });
    },
    removeFiles(data) {
      const file = data.value;
      this.$store.dispatch('actionItems/deleteFile', {
        actionItemId: this.actionItemId,
        file,
      });
    },
    openFile(value) {
      this.$store.dispatch('actionItems/downloadFile', {
        actionItemId: this.actionItemId,
        fileId: value.id,
        fileName: value.name,
      });
    },
    startResolvedWorkflow() {
      this.isDialogOn = true;
    },
    cancelSetMarker() {
      this.isDialogOn = false;
    },
    acceptSetMarker() {
      this.$store
        .dispatch('actionItems/markAsResolved', {
          actionItemId: this.actionItemId,
          accountId: this.accountId,
        })
        .then(() => {
          this.$emit('change-state');
          this.isDialogOn = false;
        });
    },
    dueDateFieldClass(dueDate) {
      if (this.state === 'resolved') {
        return '';
      }
      if (isExpired(dueDate)) {
        return 'cra-expired-marker';
      } else {
        return '';
      }
    },
  },
};
</script>
