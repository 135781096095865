<template>
  <v-container class="px-0 px-lg-2 py-0 cra-max-content" fluid>
    <v-row justify="space-between" align="center">
      <v-col cols="12" md="2">
        <v-combobox
          v-model="zonesSelect"
          :items="zoneItems"
          label="Zone"
          multiple
          outlined
          rounded
          hide-details
          dense
          append-icon="mdi-chevron-down"
          class="cra-filter-select"
          @change="(value) => setFilterZones(value)"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-combobox
          v-model="riskLevelsSelect"
          :items="riskLevelItems"
          label="Risk level"
          multiple
          outlined
          rounded
          dense
          hide-details
          append-icon="mdi-chevron-down"
          class="cra-filter-select cra-filter-select-risk-level"
          @change="(value) => setFilterRiskLevels(value)"
        >
          <template #item="{ item }">
            <span :class="['cra-filter-select-item-risk-level', getSelectFieldClass(item)]">{{
              item
            }}</span>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="2">
        <v-combobox
          v-model="customerTemperaturesSelect"
          :items="customerTemperatureItems"
          label="Customer temperature"
          multiple
          outlined
          dense
          append-icon="mdi-chevron-down"
          rounded
          class="cra-filter-select cra-filter-select-customer-temperature"
          hide-details
          @change="(value) => setFilterTemperature(value)"
        >
          <template #item="{ item }">
            <span
              :class="['cra-filter-select-item-customer-temperature', getSelectFieldClass(item)]"
              >{{ item }}</span
            >
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="2" class="cra-header-switch">
        <base-switch
          :is-active="getActiveMyAccounts"
          :label="'My Accounts'"
          @change-field="(value) => setFilterMyAccounts(value)"
        >
        </base-switch>
      </v-col>
      <v-col cols="12" md="2" :class="[!isMobile ? 'd-flex justify-end' : '']">
        <v-combobox
          v-model="currentPage"
          :items="pageItems"
          label="Page"
          outlined
          rounded
          dense
          hide-details
          append-icon="mdi-chevron-down"
          class="cra-filter-select"
          @change="(value) => getPageRoute(value)"
        >
          <template #item="{ item }">
            <span>{{ item }}</span>
          </template>
        </v-combobox>
        <hr v-if="isMobile" />
        <search-form v-if="isMobile" class="cra-mobile-search" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchForm from '../search/SearchForm';
import { mapGetters } from 'vuex';
import removeSpacesLowerCase from '../../helpers/removeSpacesLowerCase';
import BaseSwitch from '../ui/BaseSwitch';

export const ALL_ZONE = 'All zones';
export const ALL_RISK = 'All risk levels';
export const ALL_TEMPERATURE = 'All temperatures';

export default {
  name: 'AccountsFilter',
  components: { BaseSwitch, SearchForm },
  props: {
    isMobile: Boolean,
  },
  emits: ['change-filter'],
  data() {
    return {
      zoneItems: [ALL_ZONE],
      zonesSelect: [ALL_ZONE],
      riskLevelItems: [ALL_RISK],
      riskLevelsSelect: [ALL_RISK],
      customerTemperatureItems: [ALL_TEMPERATURE],
      customerTemperaturesSelect: [ALL_TEMPERATURE],
      currentPage: this.getPageName(window.location.pathname),
      pageItems: ['Active accounts', 'Watchlist', 'On the radar', 'Archive', 'Recently deleted'],
    };
  },
  computed: {
    ...mapGetters({
      getZones: 'zones/zones',
      getActiveZones: 'filters/getActiveZones',
      getRiskLevels: 'riskLevels/riskLevels',
      getActiveRiskLevels: 'filters/getActiveRiskLevels',
      getCustomerTemperatures: 'customerTemperatures/customerTemperatures',
      getActiveCustomerTemperatures: 'filters/getActiveCustomerTemperatures',
      getActiveMyAccounts: 'filters/getActiveMyAccounts',
      getLoggedInUser: 'users/getLoggedInUser',
    }),
  },
  async created() {
    await this.loadSelections();
    // zones
    const activeZones = this.getActiveZones;
    this.zonesSelect =
      activeZones.length === this.getZones.length || !activeZones.length ? [ALL_ZONE] : activeZones;
    // risk
    const activeRiskLevels = this.getActiveRiskLevels;
    this.riskLevelsSelect =
      activeRiskLevels.length === this.getRiskLevels.length || !activeRiskLevels.length
        ? [ALL_RISK]
        : activeRiskLevels;
    // customer
    const activeCustomerTemperatures = this.getActiveCustomerTemperatures;
    this.customerTemperaturesSelect =
      activeCustomerTemperatures.length === this.getCustomerTemperatures.length ||
      !activeCustomerTemperatures.length
        ? [ALL_TEMPERATURE]
        : activeCustomerTemperatures;
  },
  methods: {
    async loadSelections() {
      try {
        await this.$store.dispatch('zones/loadZones');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      this.getZones.forEach((obj) => this.zoneItems.push(obj));

      try {
        await this.$store.dispatch('riskLevels/loadRiskLevels');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      this.getRiskLevels.forEach((obj) => this.riskLevelItems.push(obj));

      try {
        await this.$store.dispatch('customerTemperatures/loadCustomerTemperatures');
      } catch (error) {
        this.error = error.message || 'Some wrong?';
        console.log('Error: ' + this.error);
      }
      this.getCustomerTemperatures.forEach((obj) => this.customerTemperatureItems.push(obj));
    },

    setFilters(fieldName, allFilterName, filters, filterGetter) {
      let activeFilters = [];
      const includesAll = filters.includes(allFilterName);
      const hasAllBeenAdded = filters.length > 1 && filters[filters.length - 1] === allFilterName;

      if (filters.length === 0) {
        this[fieldName + 'Select'] = [allFilterName];
        activeFilters = filterGetter;
      } else {
        if (includesAll && !hasAllBeenAdded) {
          const allIndex = filters.findIndex((zone) => zone === allFilterName);
          filters.splice(allIndex, 1);
          this[fieldName + 'Select'] = filters;
          activeFilters = filters;
        } else if (hasAllBeenAdded) {
          this[fieldName + 'Select'] = [allFilterName];
          activeFilters = filterGetter;
        } else {
          this[fieldName + 'Select'] = filters;
          activeFilters = filters;
        }
      }

      this.$store.commit('filters/setFilter', {
        fieldName,
        value: activeFilters,
      });
    },

    setFilterZones(zones) {
      this.setFilters('zones', ALL_ZONE, zones, this.getZones);
    },
    setFilterRiskLevels(levels) {
      this.setFilters('riskLevels', ALL_RISK, levels, this.getRiskLevels);
    },
    setFilterTemperature(temperature) {
      this.setFilters(
        'customerTemperatures',
        ALL_TEMPERATURE,
        temperature,
        this.getCustomerTemperatures
      );
    },
    setFilterMyAccounts(value) {
      this.$store.commit('filters/setFilter', {
        fieldName: 'myAccounts',
        value,
      });
      this.$store.dispatch('users/saveLoggedInUserFilter', { filterName: 'myAccounts', value });
    },
    getSelectFieldClass(val) {
      return removeSpacesLowerCase(val);
    },
    getPageName(val) {
      switch (val) {
        case '/accounts':
          return 'Active accounts';
        case '/watchlist':
          return 'Watchlist';
        case '/otr':
          return 'On the radar';
        case '/archive':
          return 'Archive';
        case '/deleted':
          return 'Recently deleted';
        default:
          return 'Select page';
      }
    },
    getPageRoute(val) {
      switch (val) {
        case 'Active accounts':
          this.$router.push('/accounts');
          break;
        case 'Watchlist':
          this.$router.push('/watchlist');
          break;
        case 'On the radar':
          this.$router.push('/otr');
          break;
        case 'Archive':
          this.$router.push('/archive');
          break;
        case 'Recently deleted':
          this.$router.push('/deleted');
          break;
        default:
          this.$router.push('/');
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import 'src/scss/globals';

div {
  color: $white;
}
.cra-mobile-search {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.cra-header-switch {
  .cra-form-field {
    margin-top: 1.5rem;
  }
}
</style>
